import {IRpcNotification, RpcWebSocketClient} from 'rpc-websocket-client'
import User from "@/model/User"
import axios, {AxiosInstance} from "axios"
import {reactive} from "@vue/reactivity"
import {userServiceApi} from "@/api/UserServiceApi"

class Call {
    method!: string
    params!: any | undefined
    resolve!: any
    reject!: any
}

export default class WebsocketClient {

    url: string = (window.location.protocol === 'http:' ? 'ws:' : 'wss:') + '//' + window.location.host + '/websocket'
    client = new RpcWebSocketClient()
    connectPromise: Promise<void> | null = null
    connectResolve: any | null = null
    connectInProgress: boolean = false
    requests: Map<string, Call> = new Map<string, Call>()
    handlerMethods: Map<string, Function> = new Map<string, Function>()
    state = reactive({
        connected: false,
        failed: false,
        failCount: 0
    })
    session: { token: string | null, user: User | null } = reactive({
        token: null,
        user: null
    })
    status: any = reactive({})
    apis: any[] = []
    stores: any[] = []

    constructor() {

        this.session.token = localStorage.getItem('token')

        this.client.configure({
            responseTimeout: 0
        })

        this.client.onNotification.push((data: IRpcNotification) => {
            if (data.method === 'logout') {
                this.logout()
            } else if (data.method === 'setSessionUser') {
                this.session.user = Object.assign(new User(), data.params[0])
                if (this.session.token) {
                    localStorage.setItem('token', this.session.token)
                }
            } else {
                const store: any | undefined = this.stores.find(store => {
                    return typeof Object(store)[data.method] === 'function'
                })
                if (store) {
                    const method: any = Object(store)[data.method]
                    if (typeof method === 'function') {
                        method.bind(store)(...data.params)
                        return
                    }
                }

                const f: Function | undefined = this.handlerMethods.get(data.method)
                if (f) {
                    f(...data.params)
                }
            }
        })

        this.client.onClose(() => {
            this.connectPromise = null
            this.state.connected = false
            setTimeout(() => {
                if (!this.state.connected) {
                    this.connectIfTokenAvailable()
                }
            }, 3000)
        })

        this.client.onError(e => {

        })

        this.connectIfTokenAvailable()
    }

    connectIfTokenAvailable() {
        if (this.tokenParameter) {
            void this.connect()
        }
    }

    connect(): Promise<any | void | undefined> {
        if (!this.connectPromise) {
            this.connectPromise = new Promise<void>(resolve => this.connectResolve = resolve)
        }
        if (!this.connectInProgress && this.tokenParameter) {
            this.connectInProgress = true
            this.client.connect(this.url + this.tokenParameter).then(() => {
                this.state.connected = true
                this.state.failed = false
                this.state.failCount = 0
                this.connectResolve()
                this.requests.forEach((value, key, map) => {
                    void this.client.call(value.method, value.params).then(result => {
                        if (map.has(key)) { //Otherwise has already been resolved/rejected
                            value.resolve(result)
                        }
                    }).catch(error => {
                        if (map.has(key)) { //Otherwise has already been resolved/rejected
                            value.reject(error)
                        }
                    }).finally(() => {
                        map.delete(key) //TODO Is modifying map keys safe here?
                    })
                })
            }).catch(() => {
                this.state.connected = false
                if (this.state.failCount > 2) {
                    this.state.failed = true
                } else {
                    this.state.failCount++
                }
            }).finally(() => {
                this.connectInProgress = false
            })
        }
        return this.connectPromise
    }

    call(method: string, params?: any, retry?: boolean): Promise<any> {
        if (this.state.connected) {
            if (retry) {
                return new Promise<unknown>((resolve, reject) => {
                    const callId = URL.createObjectURL(new Blob([])).substr(-36)
                    this.requests.set(callId, {
                        method: method,
                        params: params,
                        resolve: resolve,
                        reject: reject
                    })
                    this.client.call(method, params).then(result => {
                        if (this.requests.has(callId)) { //Otherwise has already been resolved/rejected
                            resolve(result)
                        }
                    }).catch(error => {
                        if (this.requests.has(callId)) { //Otherwise has already been resolved/rejected
                            reject(error)
                        }
                    }).finally(() => {
                        if (this.state.connected) {
                            this.requests.delete(callId)
                        }
                    })
                })
            } else {
                return this.client.call(method, params)
            }
        } else {
            return this.connect().then(() => {
                return this.call(method, params, retry)
            })
        }
    }

    get tokenParameter(): string | null {
        return this.session.token ? ('?X-Auth-Token=' + encodeURIComponent(this.session.token)) : null
    }

    login(username: string, password: string): Promise<void> {
        const formData = new FormData()
        formData.set('username', username)
        formData.set('password', password)
        return fetch(window.location.protocol + '//' + window.location.host + '/salestool-api/v1/login', {
            method: 'POST',
            body: formData,
            redirect: 'manual'
        }).then(response => {
            if (response.ok) {
                this.session.token = response.headers.get('X-Auth-Token')
                void this.connect()
            }
            return response.json().catch(() => { //No valid json
                throw {
                    timestamp: Date.now(),
                    status: response.status,
                    message: response.statusText,
                    path: '/login',
                    responseObject: null
                }
            }).then(data => {
                if (response.ok) {
                    return data
                } else {
                    throw data
                }
            })
        }).then(data => {
            this.session.user = Object.assign(new User(), data)
            localStorage.setItem('token', this.session.token || '')
        })
    }

    async refreshSettings(): Promise<void> {
        if (this.session.user) {
            const settings: any = await userServiceApi._getSettings()
            Object.assign(this.session.user.settings, settings)
        }
    }

    get fullyLoggedIn(): boolean {
        return !!this.session.token && !!this.session.user
    }

    logout() {
        this.session.token = null
        this.session.user = null
        localStorage.removeItem('token')
        try {
            //@ts-ignore
            this.client.ws.close()
        } finally {
            for (const api of this.apis) {
                if (typeof api['clearState'] === 'function') {
                    setTimeout(api.clearState.bind(api), 0)
                }
            }
            for (const store of this.stores) {
                if (typeof store['clearState'] === 'function') {
                    setTimeout(store.clearState.bind(store), 0) //After any store persists its state
                }
            }
        }
    }

    getAjaxClient(): AxiosInstance {
        const options: any = {
            baseURL:  window.location.origin + "/salestool-api/v1",
            headers: { }
        }
        if (this.session.token) {
            options.headers["X-Auth-Token"] = this.session.token
        }
        return axios.create(options)
    }

    authenticateXHR(xhr: XMLHttpRequest){
        if (this.session.token) {
            xhr.setRequestHeader("X-Auth-Token", this.session.token)
        }
    }

    registerHandlerMethod(handlerName: string, handler: Function){
        this.handlerMethods.set(handlerName, handler)
    }
}

export const rpcClient = new WebsocketClient()
