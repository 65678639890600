
import {Options, Vue} from 'vue-class-component'
import Avatar from "@/components/common/Avatar.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService"
import {rpcClient} from '@/api/WebsocketClient'
import {userServiceApi} from "@/api/UserServiceApi"
import ContextMenu from "primevue/contextmenu"
import { ref } from "@vue/reactivity"
import {Router, useRouter} from "vue-router"
import Badge from 'primevue/badge'
import OverlayPanel from "primevue/overlaypanel"
import TieredMenu from "primevue/tieredmenu"
import SettingsUtil from "@/util/SettingsUtil"
import {groupServiceApi} from "@/api/GroupServiceApi"

@Options({
  components: {
    //@ts-ignore
    Avatar, ContextMenu, Badge, OverlayPanel, TieredMenu
  },
  //@ts-ignore
  props: {
    links: Array,
    collapse: Boolean,
  },
  emits: ['showHelpOverlay']
})
export default class SideBar extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  rpcClient = rpcClient

  links!: any[]
  collapse!: boolean
  portalURL: any | undefined

  //@ts-ignore
  menu: TieredMenu = ref<TieredMenu | null>(null);

  menuItems: any[] = []
  notificationCount: number = 0

  helpOverlayIsVisible: boolean = false

  get userName(): string | null {
    return rpcClient.session?.user?.userName || null
  }

  get userDisplayName(): string | null {
    return rpcClient.session?.user?.displayName || null
  }

  get profilePicture(): CachedImage | null {
    if (this.userName) {
      return imageLoadingService.getCachedImage(`/salestool-api/v1/users/${this.userName}/picture`)
    } else {
      return null
    }
  }

  get isAdmin(): boolean {
    return rpcClient.session?.user?.posixGroupName === 'admin'
  }

  get isManager(): boolean {
    return !!rpcClient?.session?.user?.memberOf?.find((groupDn) => {
      return groupDn.startsWith('cn=signing,')
    })
  }

  openSettingsMenu(e: Event) {
    this.updateSettingsMenuIcons()
    void this.$nextTick(() => {
      this.menu.show(e)
    })
  }

  openUserMenu(e: Event) {
    this.updateUserMenuIcons()
    void this.$nextTick(() => {
      this.menu.show(e)
    })
  }

  updateSettingsMenuIcons() {
    this.menuItems = [
      {
        label: this.i18n.$gettext("Manuals"), icon: 'cil-info-circle', command:() => {
          void this.router.push('/manual')
        }
      },
      {
        label: this.i18n.$gettext("User Preferences"), icon: 'cil-user', command:() => {
          void this.router.push('/settings')
        }
      }
    ]

    if (this.isAdmin) {
      this.menuItems.push({
        label: this.i18n.$gettext("System Settings"), icon: 'cil-settings-alt', command:() => {
          void this.router.push('/settings/system')
        }
      })
    }
  }

  updateUserMenuIcons() {
    this.menuItems = [
      {
        label: this.i18n.$gettext("Manuals"), icon: 'cil-info-circle', command:() => {
          void this.router.push('/manual')
        }
      },
      {
        label: this.i18n.$gettext("My Status"), icon: 'cil-mood-good', items: [
          {
            label: 'Online',
            command:() => {
              void userServiceApi._updateStatus('ONLINE')
            }
          },
          {
            label: 'Away',
            command:() => {
              void userServiceApi._updateStatus('AWAY')
            }
          },
          {
            label: 'DND',
            command:() => {
              void userServiceApi._updateStatus('DND')
            }
          },
          {
            label: 'Offline',
            command:() => {
              void userServiceApi._updateStatus('OFFLINE')
            }
          }
        ]
      },
      {
        label: this.i18n.$gettext("My Account"), icon: 'cil-user-secret', command:() => {
          void this.router.push('/account')
        }
      },
      {
        label: this.i18n.$gettext("User Preferences"), icon: 'cil-user', command:() => {
          void this.router.push('/settings')
        }
      }
    ]

    this.menuItems.push({
      label: this.i18n.$gettext("Logout"), icon: 'cil-account-logout', command:() => {
        rpcClient.logout()
        void this.router.push('/')
      }
    })
  }

  currentRouteStartsWith(h: string): boolean {
    const path = this.$router.currentRoute?.value?.path || this.$router.currentRoute?.path
    if(h === "/"){
      return path === h
    } else {
      return path?.startsWith(h)
    }
  }

  showHelpOverlay(){
    SettingsUtil.setHelpCenterSeen()
    this.$emit('showHelpOverlay')
  }

  mounted() {
    //Preload users here because they are needed in multiple places
    userServiceApi.getUsers()
  }
}
