export default class Categories {
  names!: string[] | null;
  type!: string | null;
  preference!: number | null;
  alternativeId!: string | null;

  constructor() {
    this.names = null;
    this.type = null;
    this.preference = null;
    this.alternativeId = null;
  }
}
