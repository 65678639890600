
import {Options, Vue} from 'vue-class-component'
import LoginForm from './components/LoginForm.vue'
import {rpcClient} from '@/api/WebsocketClient'
import SideBar from "@/components/SideBar.vue"
import MenuBar from "@/components/common/MenuBar.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import ConfirmDialog from "primevue/confirmdialog"
import Button from "primevue/button"
import Toast from "primevue/toast"
import Channel from "@/model/directory/Channel"
import {projectServiceApi} from "@/api/ProjectServiceApi"
import {channelServiceApi} from "@/api/ChannelServiceApi"
import MailFolder from "@/model/directory/MailFolder"
import {mailFolderServiceApi} from "@/api/MailFolderServiceApi"
import desktopNotificationUtil from "@/util/DesktopNotificationUtil"
import Dialog from "primevue/dialog"
import {Watch} from "vue-property-decorator"
import migrationStatusService, {MigrationStatus} from "@/util/migrationStatus"
import {reactive, ref} from "@vue/reactivity"
import {Router, useRouter} from "vue-router"
import {calendarInboxServiceApi} from "@/api/CalendarInboxServiceApi"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import SWR from "@/api/SWR"
import CalendarEvent from "@/model/entry/Event"
import SchedulingObject from "@/model/SchedulingObject"
import Event from "@/model/entry/Event"
import {eventServiceApi} from "@/api/EventServiceApi"
import {taskServiceApi} from "@/api/TaskServiceApi"
import 'flatpickr/dist/flatpickr.css'


@Options({
  components: {
    //@ts-ignore
    LoginForm, SideBar, MenuBar, ConfirmDialog, Button, Toast, Dialog
  }
})
export default class App extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  rpcClient = rpcClient
  channelApi = channelServiceApi
  showModalForNativeNotify: boolean = false
  showHelpOverlay: boolean = false

  get shouldShowModalForNotifications(){
    return desktopNotificationUtil.shouldAskForNotificationPermission && this.showModalForNativeNotify
  }

  @Watch('shouldAskForNotificationPermission')
  reactOnGivenNotificationPermission(){
    this.showModalForNativeNotify = false
  }

  get migrationStatus(): MigrationStatus {
    return migrationStatusService.migrationStatus
  }

  get hasAnyMigrationStatus(): boolean {
    return migrationStatusService.hasAnyMigrationStatus
  }

  get hasActiveMigration(): boolean {
    return migrationStatusService.hasMigrationStatus
  }

  get showMigrationBanner(): boolean {
    return rpcClient.fullyLoggedIn && this.hasActiveMigration
  }

  get shouldAskForNotificationPermission() {
    return desktopNotificationUtil.shouldAskForNotificationPermission
  }

  askForNotification() {
    desktopNotificationUtil.askForNotification()
    this.showModalForNativeNotify = true
  }

  get links() {
    const links: any[] = [
      {
        href: '/',
        icon: 'cil-calendar cil-fw',
        text: this.i18n.$gettext('Calendar'),
        notificationCount: this.inboxCount
      },
      {
        href: '/contacts',
        icon: 'cil-group cil-fw',
        text: this.i18n.$gettext('Contacts')
      },
      {
        href: '/tasks',
        icon: 'cil-task cil-fw',
        text: this.i18n.$gettext('Tasks'),
        notificationCount: taskServiceApi.overDueCount
      }
    ]
    return links
  }

  get projects() {
    return projectServiceApi.getProjects(10000).data || []
  }

  get currentProjectId(): string | null {
    if (this.projects && this.projects.length > 0) {
      return this.projects[0].id
    } else {
      return null
    }
  }

  get channels(): Channel[] {
    const channels = []
    let swr1 = this.channelApi.getChannelsForTeam(this.currentProjectId || null, false)
    let swr2 = this.currentProjectId ? this.channelApi.getChannelsForTeam(null, false) : undefined
    if (swr1.data && (!swr2 || swr2.data)) {
      channels.push(...swr1.data)
      if (swr2?.data) channels.push(...swr2.data.filter(c => c.isDirect))
    }
    return channels.filter(c => !c.deleted)
  }

  get allUnreadChannels(): Channel[] {
    return this.channels.filter((ch: Channel) => {
      return ch.unreadMsgCount ? ch.unreadMsgCount > 0 : false
    })
  }

  get unreadMessageCount(): number | string {
    let sum = 0
    this.allUnreadChannels.forEach((ch: Channel) => {
      sum += ch.unreadMsgCount || 0
    })
    return sum > 99 ? '99+' : sum
  }

  get unreadEmailCount(): number | string {
    const mailFolders: MailFolder[] = mailFolderServiceApi.getFolders(false).data || []
    const numberOfUnread: number = this.numberOfUnreadIncludingSubfolders(mailFolders)
    if (numberOfUnread > 99) {
      return "99+"
    } else {
      return numberOfUnread
    }
  }

  numberOfUnreadIncludingSubfolders(mailFolders: MailFolder[]): number {
    let numberOfUnread: number = 0
    for (const folder of mailFolders) {
      if (!['\\Junk', '\\Trash'].includes(folder.type || '') && ![ 'Junk', 'Trash' ].includes(folder.name || '')) {
        numberOfUnread += folder.numberOfUnread || 0
        if (numberOfUnread < 99 && folder.subFolders?.length) {
          numberOfUnread += this.numberOfUnreadIncludingSubfolders(folder.subFolders)
          if (numberOfUnread >= 99) {
            break
          }
        }
      }
    }
    return numberOfUnread
  }

  get inboxCount(): number | string {
    let count = this.inbox.length || 0
    if (count > 99) return "99+"
    else return count
  }

  get inbox(): { eventId: string, event: CalendarEvent | null, messages: SchedulingObject[] }[] {
    const swr: SWR<SchedulingObject[], string[]> = calendarInboxServiceApi.readInbox()
    const inbox: Map<string, { eventId: string, event: CalendarEvent, messages: SchedulingObject[] }> = new Map<string, { eventId: string, event: CalendarEvent, messages: SchedulingObject[] }>();
    (swr.data || []).forEach((s: SchedulingObject) => {
      const eventWithRequest: CalendarEvent | undefined = s.eventsFromRequest ? s.eventsFromRequest.find(e => !!e.requestFor) : undefined
      const requestFor: CalendarEvent | null | undefined = eventWithRequest?.requestFor
      if (eventWithRequest && requestFor) {
        const eventId: string = requestFor.originalId || ''
        eventWithRequest.requestFor = requestFor
        let swr: SWR<Event | null, string> | null = null
        if (eventId) {
          swr = eventServiceApi.getEvent(eventId)
          eventWithRequest.requestFor = swr.data || requestFor
        }
        let objects = inbox.get(eventId)
        if (!objects) {
          objects = reactive({
            eventId: eventId,
            event: eventWithRequest,
            messages: []
          })
          inbox.set(eventId, objects)
          if (swr) {
            swr.call?.promise?.finally(() => {
              if (swr) {
                eventWithRequest.requestFor = swr.data || eventWithRequest.requestFor
              }
            })
          }
        } else if (!objects.event) {
          objects.event = eventWithRequest
        }
        objects.messages.push(s)
      } else if (s.eventsFromRequest && s.eventsFromRequest.length > 0) {
        const eventId: string = s.eventsFromRequest[0].uid || ''
        let objects = inbox.get(eventId)
        if (!objects) {
          objects = {
            eventId: eventId,
            event: s.eventsFromRequest[0],
            messages: []
          }
          inbox.set(eventId, objects)
        }
        objects.messages.push(s)
      }
    })
    inbox.forEach(value => {
      value.messages = value.messages.sort((i, j) => {
        if (i.eventsFromRequest && j.eventsFromRequest) {
          const iDate = i.eventsFromRequest[0].stamp || i.eventsFromRequest[0].lastModified
          const jDate = j.eventsFromRequest[0].stamp || j.eventsFromRequest[0].lastModified
          return SortAndFilterUtil.compare(jDate, iDate)
        } else {
          return 0
        }
      })
    })
    return Array.from(inbox.values()).sort((i, j) => {
      if (i.messages && i.messages[0].eventsFromRequest && j.messages && j.messages[0].eventsFromRequest) {
        const iEvents = i.messages[0].eventsFromRequest
        const jEvents = j.messages[0].eventsFromRequest
        if (iEvents && jEvents) {
          const iDate = iEvents[0].stamp || iEvents[0].lastModified
          const jDate = jEvents[0].stamp || jEvents[0].lastModified
          return SortAndFilterUtil.compare(jDate, iDate)
        }
      }
      return 0
    })
  }

  get collapseSidebar(): boolean {
    const params = this.$route.params
    let setParams = 0
    for (const param in params) {
      let val = params[param]
      if (!!val) setParams++
    }
    return setParams > 0
  }
}
