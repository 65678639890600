

import {Options, Vue} from "vue-class-component"
import StructuredName from "@/model/common/carddav/StructuredName"
import InputText from "primevue/inputtext"
import Chips from "primevue/chips"
import breakpointUtil from "@/util/BreakpointUtil"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import i18n from "@/util/i18n"
import {useGettext} from "@jshmrtn/vue3-gettext"

@Options({
  components: {AnimatedInput, Chips},
  //@ts-ignore
  props: {
    name: [StructuredName, Object],
    readOnly: {
      type: Boolean,
      default: false
    },
    additional: {
      type: Boolean,
      default: true
    }
  },
  emits: [ 'blur' ]
})
export default class StructuredNameField extends Vue {

  i18n = useGettext()

  get isOnMobile(){
    return breakpointUtil.isOnMobile()
  }
}
