

import ProgressSpinner from 'primevue/progressspinner'
import {Options, Vue} from "vue-class-component"
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"


@Options({
  components: {ProgressSpinner},
  //@ts-ignore
  props: {
    size: {
      type: Number,
      default: 32
    },
    src: {
      type: String,
      default: ""
    },
    cachedImage: {
      type: CachedImage,
      default: null
    },
    username: {
      type: String,
      default: null
    },
    generateInitials: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    }
  },
  emits: []
})
export default class Avatar extends Vue {

  src!: string
  cachedImage!: CachedImage
  username!: string
  label!: string
  generateInitials = false
  i18n: Language = useGettext()

  get userImage(): CachedImage | null {
    const userName: string | undefined | null = this.username
    if (userName) {
      return imageLoadingService.getCachedImage(`/salestool-api/v1/users/${userName}/picture`)
    } else {
      return null
    }
  }

  get userInstance(): User | null {
    return userServiceApi.getUser(this.username) || null
  }

  get userLabel(): string {
    const userName: string | undefined | null = this.username
    const user: User | null = this.userInstance
    if(!user) return userName.substring(0,1)
    return user.givenname?.substring(0,1) + "" + user.surname?.substring(0,1)
  }

  get cachedImageInstance(): CachedImage | null {
    if(this.userImage) return this.userImage
    if(this.cachedImage) return this.cachedImage
    if(this.src) return imageLoadingService.getCachedImage(this.src)
    return null
  }

  get hasImageSrc(): boolean {
    if(this.cachedImageInstance){
      return !this.cachedImageInstance.error
    } else {
      return false
    }
  }

  get imageLabel(): string {
    if(this.username){
      return this.userLabel
    } else {
      if(this.generateInitials){
        return this.initials
      } else {
        return this.label || ""
      }

    }
  }

  get initials(): string {
    const fullName = this.label
    if(!fullName) return "X"
    const parts = fullName.split(" ")
    let result = ""
    parts.forEach((part: string, idx: number) => {
      if(idx == 0 || idx == parts.length - 1) result += part.charAt(0)
    })
    return result
  }

  get statusIndicatorStyle(): any {
    let color = "transparent"
    if(this.userInstance){
      switch (this.userInstance.status) {
        case "ONLINE":
          color = "#81c17e"
          break
        case "OFFLINE":
          color = "transparent"
          break
        case "AWAY":
          color = "#f2a541"
          break
        case "DND":
          color = "#e94b50"
          break
      }
    }
    return {"background": color}
  }

}
