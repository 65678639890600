<template>
  <div class="textarea p-inputtext p-0 d-flex flex-column">
    <div v-if="editor" class="flex-shrink-0 flex-grow-0 d-flex flex-row justify-content-between">
      <div v-if="showToolBar" class="flex-shrink-1 compose-buttons-format">
        <Button
          icon="cil-bold"
          class="p-button-text p-button-secondary"
          :class="{ 'is-active': editor.isActive('bold') }"
          @click="editor.chain().focus().toggleBold().run()"
        />
        <Button
          icon="cil-italic"
          class="p-button-text p-button-secondary"
          :class="{ 'is-active': editor.isActive('italic') }"
          @click="editor.chain().focus().toggleItalic().run()"
        />
        <Button
          icon="cil-strikethrough"
          class="p-button-text p-button-secondary"
          :class="{ 'is-active': editor.isActive('strike') }"
          @click="editor.chain().focus().toggleStrike().run()"
        />
        <Button
          icon="cil-list"
          class="p-button-text p-button-secondary"
          :class="{ 'is-active': editor.isActive('bulletList') }"
          @click="editor.chain().focus().toggleBulletList().run()"
        />
        <Button
          icon="cil-list-numbered"
          class="p-button-text p-button-secondary"
          :class="{ 'is-active': editor.isActive('orderedList') }"
          @click="editor.chain().focus().toggleOrderedList().run()"
        />
        <Button
          icon="cil-task"
          class="p-button-text p-button-secondary"
          :class="{ 'is-active': editor.isActive('taskList') }"
          @click="editor.chain().focus().toggleTaskList().run()"
        />
      </div>
      <div>
        <slot name="actions" />
      </div>
    </div>
    <editor-content
      class="p-0 flex-shrink-0 flex-grow-1 tiptap-content"
      :editor="editor"
      style="cursor: text"
      @click="focus"
    />
  </div>
</template>

<script lang="ts">
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Typography from "@tiptap/extension-typography"

import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import {Mention} from "@tiptap/extension-mention"
import {Placeholder} from "@tiptap/extension-placeholder"
import {Underline} from "@tiptap/extension-underline"
//@ts-ignore
import suggestion from "./chatMessageComposer/suggestion"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {TaskList} from '@tiptap/extension-task-list'
import {TaskItem} from "@/util/tip-tap-task-item"
import { createMarkdownEditor, MarkdownEditor as MDEditor } from "tiptap-markdown"

const MarkdownEditor = createMarkdownEditor(Editor)

@Options({
  components: { EditorContent, Button },
  //@ts-ignore
  props: {
    showToolBar: { type: Boolean, default: true },
    editable: { type: Boolean, default: true },
    previewContent: { type: String, default: "" },
    placeholder: String
  },
  emits: [
    'changed'
  ]
})
export default class TipTapTextArea extends Vue {

  editor: MDEditor | null = null

  i18n: Language = useGettext()

  editable!: boolean
  placeholder!: string

  previewContent!: string
  originalContent: string = ""

  mounted() {
    const extensions: any[] = [
      StarterKit,
      Underline,
      Highlight,
      Typography,
      TaskList,
      TaskItem.configure({
        HTMLAttributes: {
          class: 'taskItem',
        },
      }),
      Placeholder.configure({
        placeholder: this.placeholder || '',
      }),
    ]

    this.editor = new MarkdownEditor({
      editable: this.editable,
      content: '',
      extensions: extensions,
      editorProps: {
        attributes: {
          class: 'custom-editor p-3',
        }
      }
    })
    this.editor.on('update', ({ editor }: { editor: any }) => {
      // The content has changed.
      this.$emit('changed')
    })
    if (this.previewContent) {
      this.setContent(this.previewContent)
    }
  }

  getHTML(): string | undefined {
    return this.editor?.getHTML()
  }

  insertContent(content: string | undefined) {
    if (content && this.editor) {
      this.editor.chain().focus().insertContent(content).run()
    }
  }

  setContent(content: string | undefined) {
    if (content && this.editor) {
      this.editor.chain().focus().setContent(content).run()
      this.originalContent = this.getMarkdown()
    }
  }

  hasChanges(): boolean {
    return this.getMarkdown() != this.originalContent
  }

  getMarkdown(): string {
    return this.editor?.getMarkdown() || ""
  }

  addEmojiToEditor(emoji: string): void {
    if (this.editor) this.editor.chain().focus().insertContent(emoji).run()
  }

  clearContent(): void {
    if (this.editor) this.editor.commands.clearContent()
  }

  focus(): void {
    this.$nextTick(() => {
      //@ts-ignore
      if (this.editor) this.editor.view.dom.focus()
    })
  }

  beforeUnmount() {
    this.editor?.destroy()
  }
}
</script>

<style lang="scss" scoped>
@import "node_modules/elly-bs4/sass/variables";


$button_hover_color_editor: $uniki_primary_dark;
.p-button.is-active {
  color: $button_hover_color_editor;

  &:hover {
    color: $button_hover_color_editor !important;
  }
}

@media screen and (max-width:767px){
  .compose-buttons-format {
    display: none;
  }
}

</style>
