<template>
  <div class="status-wrapper style--none " tabindex="-1">
    <div v-if="hasImageSrc" class="profile-icon">
      <ProgressSpinner v-if="!cachedImageInstance.loaded" :style="{height: size + 'px', width: size + 'px'}" />
      <img
        v-else
        :alt="i18n.$gettext('User Avatar')"
        class="Avatar Avatar-md"
        :style="{height: size + 'px', width: size + 'px'}"
        :src="cachedImageInstance.cached"
      >
    </div>
    <div v-else class="profile-icon">
      <div
        class="Avatar Avatar-md avatar-label"
        :style="{height: size + 'px', width: size + 'px', lineHeight: size + 'px', fontSize: (size / 3) + 'px' }"
      >
        {{ imageLabel }}
      </div>
    </div>
    <span v-if="username" class="status" style="overflow: visible">
      <div class="avatar-status-indicator" :style="statusIndicatorStyle" />
    </span>
  </div>
</template>

<script lang="ts">

import ProgressSpinner from 'primevue/progressspinner'
import {Options, Vue} from "vue-class-component"
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"


@Options({
  components: {ProgressSpinner},
  //@ts-ignore
  props: {
    size: {
      type: Number,
      default: 32
    },
    src: {
      type: String,
      default: ""
    },
    cachedImage: {
      type: CachedImage,
      default: null
    },
    username: {
      type: String,
      default: null
    },
    generateInitials: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    }
  },
  emits: []
})
export default class Avatar extends Vue {

  src!: string
  cachedImage!: CachedImage
  username!: string
  label!: string
  generateInitials = false
  i18n: Language = useGettext()

  get userImage(): CachedImage | null {
    const userName: string | undefined | null = this.username
    if (userName) {
      return imageLoadingService.getCachedImage(`/salestool-api/v1/users/${userName}/picture`)
    } else {
      return null
    }
  }

  get userInstance(): User | null {
    return userServiceApi.getUser(this.username) || null
  }

  get userLabel(): string {
    const userName: string | undefined | null = this.username
    const user: User | null = this.userInstance
    if(!user) return userName.substring(0,1)
    return user.givenname?.substring(0,1) + "" + user.surname?.substring(0,1)
  }

  get cachedImageInstance(): CachedImage | null {
    if(this.userImage) return this.userImage
    if(this.cachedImage) return this.cachedImage
    if(this.src) return imageLoadingService.getCachedImage(this.src)
    return null
  }

  get hasImageSrc(): boolean {
    if(this.cachedImageInstance){
      return !this.cachedImageInstance.error
    } else {
      return false
    }
  }

  get imageLabel(): string {
    if(this.username){
      return this.userLabel
    } else {
      if(this.generateInitials){
        return this.initials
      } else {
        return this.label || ""
      }

    }
  }

  get initials(): string {
    const fullName = this.label
    if(!fullName) return "X"
    const parts = fullName.split(" ")
    let result = ""
    parts.forEach((part: string, idx: number) => {
      if(idx == 0 || idx == parts.length - 1) result += part.charAt(0)
    })
    return result
  }

  get statusIndicatorStyle(): any {
    let color = "transparent"
    if(this.userInstance){
      switch (this.userInstance.status) {
        case "ONLINE":
          color = "#81c17e"
          break
        case "OFFLINE":
          color = "transparent"
          break
        case "AWAY":
          color = "#f2a541"
          break
        case "DND":
          color = "#e94b50"
          break
      }
    }
    return {"background": color}
  }

}
</script>

<style scoped lang="scss">
@import "node_modules/elly-bs4/sass/variables";

.Avatar.Avatar-md {
  font-size: 12px;
}

.Avatar, .p-avatar {
  clip-path: polygon(50% 0, 93% 25%, 93% 75%, 50% 100%, 7% 75%, 7% 25%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: sub;
}

button.style--none {
  background: transparent;
  border: none;
  padding: 0;
}

.status-wrapper {
  position: relative;
  align-self: center;
}

.status-wrapper .status {
  border-radius: 100px;
  height: 15px;
  line-height: 0;
  margin: 0;
  position: absolute;
  right: -3px;
  top: 20%;
  width: 15px;
}

.status {
  display: inline-block;
  margin: 0 7px 0 1px;
  position: relative;
  top: 2px;
  width: 15px;

  .avatar-status-indicator {
    $avatar-status-indicator-size: 0.7rem;
    display: inline-block;
    position: absolute;
    left: $avatar-status-indicator-size / 2;
    bottom: - $avatar-status-indicator-size;
    height: $avatar-status-indicator-size;
    width: $avatar-status-indicator-size;
    border-radius: 3px;
    transition: background-color 200ms linear;
  }
}

$online-indicator: rgb(6, 214, 160);
$away-indicator: rgb(255, 188, 66);
$dnd-indicator: rgb(247, 67, 67);

.status .online--icon {
  fill: $online-indicator;
}
.status .away--icon {
  fill: $away-indicator;
}
.status .dnd--icon {
  fill: $dnd-indicator;
}

.avatar-label {
  text-align: center;
  vertical-align: center;
  background-color: $uniki_primary;
  color: white;
}

</style>
