import {reactive} from "@vue/reactivity"
import axios from "axios"

export type MigrationStatus = {
    migration_options: {
        [key: string]: string
    },
    migration_state: {
        [key: string]: string
    },
    imap_state: {
        [key: string]: string
    }
}

class MigrationStatusService {

    timeout: number = -1

    migrationStatus: MigrationStatus = reactive({
        migration_options: {},
        migration_state: {},
        imap_state: {}
    })

    start(){
        if(this.timeout >= 0){
            return //Already running!
        }
        this.timeout = setTimeout(() => {
            this.refreshMigrationStatus()
        }, 1000)
    }

    get hasMigrationStatus(): boolean {
        if (this.migrationStatus && this.migrationStatus.migration_state) {
            const state = this.migrationStatus.migration_state
            for (const key of Object.keys(state)) {
                if (state[key] === 'RUNNING' || state[key] === 'PENDING') {
                    return true
                }
            }
        }
        return false
    }

    get hasAnyMigrationStatus(): boolean {
        if (this.migrationStatus && this.migrationStatus['migration_state']) {
            const state = this.migrationStatus['migration_state']
            return Object.keys(state).length > 0
        }
        return false
    }

    getMigrationStatusForComponent(component: string): string {
        let stateString = ""
        if (this.migrationStatus && this.migrationStatus['migration_state']) {
            const state = this.migrationStatus['migration_state']
            stateString =  state[component]
        }
        return stateString
    }

    refreshMigrationStatus() {
        void axios.get('/salestool-api/v1/migration').then(response => {
            Object.assign(this.migrationStatus, response.data)


            if (this.hasMigrationStatus) {
                this.timeout = setTimeout(() => {
                    this.refreshMigrationStatus()
                }, 5000)
            }
        }).catch(error => {
            this.timeout = setTimeout(() => {
                this.refreshMigrationStatus()
            }, 5000)
        })
    }
}

const migrationStatusService = new MigrationStatusService()

export default migrationStatusService
