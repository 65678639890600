import {createApp} from 'vue'
import App from './App.vue'
import i18n, {localeForPrimeVue} from "@/util/i18n"
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'
import DialogService from 'primevue/dialogservice'
import Tooltip from 'primevue/tooltip'
import EmojiPicker from './directives/emoji-picker'

//import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

import 'vue-advanced-cropper/dist/style.css'

//Route imports:
import router from "@/router"

//Print Versions:

declare const __GIT_HASH__: string
declare const __GIT_VERSION__: string

console.log(__GIT_VERSION__) // eslint-disable-line no-console
console.log(__GIT_HASH__) // eslint-disable-line no-console

// @ts-ignore
const app = createApp(App)
// @ts-ignore
//app.component('v-select', vSelect);
app.use(PrimeVue, { locale: localeForPrimeVue})
app.use(ToastService)
app.use(ConfirmationService)
app.use(DialogService)
app.use(i18n)
app.use(router)
app.directive('tooltip', Tooltip)
app.directive('emoji', EmojiPicker)
app.mount('#groupware')
export const toastServiceInstance = app.config.globalProperties.$toast



