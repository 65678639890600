<template>
  <Dialog
    v-model:visible="visibleInternal"
    position="right"
    :style="modalStyle"
    :draggable="false"
    :close-on-escape="false"
    :closable="false"
    @hide="hide"
  >
    <template #header>
      <div v-if="editMode" class="btn-group d-flex flex-shrink-0 flex-grow-1 align-self-start">
        <Button
          class="p-button-raised p-button-success mr-2"
          icon="cil-save"
          :label="i18n.$gettext('Save')"
          :loading="saveLoading"
          @click="save"
        />
        <Button
          v-if="attachmentcontrol && !isAttendeeAndNotOrganizer"
          v-tooltip="i18n.$gettext('Import Event from ICS')"
          class="p-button-raised p-button-secondary mr-2"
          icon="cil-arrow-thick-from-bottom"
          @click="attachmentcontrol.openNativeFileChooser"
        />
        <Button
          v-tooltip="i18n.$gettext('Color')"
          class="p-button-raised p-button-secondary mr-2"
          icon="cil-color-palette"
          :style="{ 'background-color': color, 'border': '1px solid ' + color}"
          @click="colorPicker.toggle($event)"
        />
        <Button
          v-tooltip="i18n.$gettext('Conference')"
          class="p-button-raised mr-2"
          :class="(conferences && conferences.length > 0) ? 'p-button-success' : 'p-button-secondary'"
          icon="cil-video"
          :style="{ 'background-color': color, 'border': '1px solid ' + color}"
          :disabled="!featureSubset.conferenceUrl"
          @click="addOrRemoveVideoConference"
        />
      </div>
      <div v-else-if="event" class="h-100 d-flex flex-wrap flex-shrink-0 flex-grow-1 align-self-start align-items-center">
        <h3 class="mb-0 mr-6 flex-grow-1" style="text-transform: initial; width: 0; max-width: 100%">
          {{ event.summary || '' }}
        </h3>
      </div>
      <div class="d-flex flex-shrink-0 align-self-start justify-content-end">
        <!--<Button
          v-if="canEdit"
          class="p-button-raised mr-2"
          :icon="editMode ? 'cil-x' : 'cil-pencil'"
          :label="editMode ? i18n.$pgettext('Abbrechen', 'Cancel') : i18n.$gettext('Edit')"
          :loading="saveLoading"
          @click="toggleEditMode"
        />-->
        <Button
          icon="cil-x"
          class="p-button-text p-button-secondary"
          @click="confirmClose"
        />
      </div>
    </template>
    <div v-if="visibleInternal && event && contact" class="d-flex flex-column w-100 h-100">
      <div class="row flex-grow-1 flex-shrink-1" style="min-height: 0">
        <div class="col-6 d-flex flex-column h-100 pt-2" style="overflow: auto">
          <div class="row mb-2 pb-2 separator-bottom">
            <div class="col-6 pr-1">
              <Dropdown
                  v-model="source"
                  class="w-100"
                  :disabled="!editMode || hasOnlyOneSourceOption"
                  :options="sourceOptions"
                  option-label="label"
                  option-value="value"
                  :label="i18n.$pgettext('leadform', 'Source')"
              />
            </div>
            <div class="col-6 pl-1">
              <Dropdown
                  v-model="campaign"
                  class="w-100"
                  :disabled="!editMode"
                  :options="campaignOptions"
                  option-label="label"
                  option-value="value"
                  :label="i18n.$pgettext('leadform', 'Campaign')"
              />
            </div>
          </div>
          <AnimatedInput
              v-model="contact.organization.titles[0]"
              :disabled="isAttendeeAndNotOrganizer"
              class="w-100 mb-3"
              type="text"
              :label="i18n.$gettext('Organization')"
          />
          <StructuredNameField
              class="w-100 mb-3"
              :name="contact.structuredName"
              :disabled="isAttendeeAndNotOrganizer"
              :additional="false"
          />
          <PhoneField
              v-for="(phone, index) in contact.phones"
              :key="phone"
              :phone="phone"
              :label="phone.types[0] === 'cell' ? i18n.$gettext('Mobile Phone') : i18n.$gettext('Landline')"
              :read-only="!editMode"
              :isv4="true"
              :show-delete="false"
              :show-type="false"
              class="mb-2"
          />
          <EmailField
              v-for="(email, index) in contact.emailAddresses"
              :key="email"
              :email="email"
              :read-only="!editMode"
              :isv4="true"
              :show-delete="false"
              :show-type="false"
              @blur="addOrUpdateAttendee"
          />
          <AddressField
              v-for="(address, index) in contact.addresses"
              :key="index"
              :address="address"
              :read-only="!editMode"
              :isv4="true"
              :show-delete="false"
              :show-type="false"
          />
          <div class="flex-shrink-0 flex-grow-1 pb-4" style="min-height: 200px">
            <TipTapTextArea ref="notes" class="w-100 h-100" :placeholder="i18n.$pgettext('leadform', 'Notes')" />
          </div>
        </div>
        <div v-if="editMode" class="col-6 h-100 d-flex flex-column pt-2 separator-left" style="overflow: auto">
          <AnimatedInput
              v-model="summary"
              :disabled="isAttendeeAndNotOrganizer"
              class="w-100 mb-3"
              type="text"
              :label="i18n.$gettext('Event Summary')"
          />
          <!--<div class="mb-3">
            <Dropdown
                v-if="writableCalendars.length > 1 || !calendarId"
                v-model="calendarId"
                show-clear
                inline
                :options="writableCalendars"
                option-label="name"
                option-value="originalId"
                :label="i18n.$gettext('Calendar')"
            />
            <span v-if="categories && categories.length" class="p-float-label mt-4">
              <AutoComplete
                  v-model="categories"
                  :disabled="isAttendeeAndNotOrganizer"
                  multiple
                  :suggestions="categoriesOptions"
                  :empty-search-message="i18n.$gettext('Add new category with \'Enter\' key.')"
                  @complete="filterCategoriesOptions"
              />
              <label><translate>Categories</translate></label>
            </span>
          </div>-->
          <div class="row mb-3">
            <div class="col col-md-6">
              <DatePicker
                  v-model="start"
                  :disabled="isAttendeeAndNotOrganizer"
                  inline
                  :label="i18n.$gettext('Start')"
                  :show-time="true"
                  month-navigator
                  year-navigator
                  year-range="1980:2050"
                  @update:modelValue="watchStart"
              />
            </div>
            <div class="col col-md-6">
              <DatePicker
                  v-model="end"
                  :disabled="isAttendeeAndNotOrganizer"
                  inline
                  :label="i18n.$gettext('End')"
                  :show-time="true"
                  month-navigator
                  year-navigator
                  year-range="1980:2050"
                  :min-date="start"
                  @update:modelValue="watchEnd"
              />
            </div>
          </div>
          <div v-if="showTimezonesSelector" class="row mb-3">
            <div class="col-12">
              <Dropdown
                  v-model="timeZone"
                  show-clear
                  inline
                  :options="browserSupportedTimezones"
                  option-label="label"
                  option-value="value"
                  :filter="true"
                  :label="i18n.$gettext('Timezone')"
                  class="w-100"
              />
            </div>
          </div>
          <div :class="(conferences && conferences.length > 0) ? 'mb-3' : 'mb-4'">
            <div class="p-inputgroup">
              <span class="p-float-label">
                <AutoComplete
                    v-model="newParticipant"
                    :disabled="isAttendeeAndNotOrganizer"
                    :suggestions="selectableUsers"
                    @complete="filterUsers"
                    @item-select="addAttendee"
                />
                <label><translate>Add Participants</translate></label>
              </span>
              <Button
                  v-tooltip="i18n.$gettext('Add')"
                  :disabled="isAttendeeAndNotOrganizer"
                  icon="cil-plus"
                  class="p-button-success h-100"
                  @click="addAttendee"
              />
            </div>
            <div v-for="attendee in attendees" :key="attendee.attendee.email" class="d-flex flex-row align-items-center mt-2">
              <Avatar
                  :username="attendee.user?.userName"
                  :label="attendee.attendee.email"
                  generate-initials
                  class="mr-2"
              />
              <strong class="flex-grow-1 mb-0" style="width: 1px">
                {{ attendee.attendee.email + (attendee.attendee.name ? ' (' + attendee.attendee.name + ')' : '') }}
              </strong>
              <Chip
                  v-if="!editMode"
                  :label="getAttendeeStatus(attendee.attendee.status)"
                  :class="getChipColor(attendee.attendee.status)"
              />
              <Dropdown
                  v-model="attendee.attendee.participationLevel"
                  :disabled="isAttendeeAndNotOrganizer"
                  small
                  class="mb-0 mr-4"
                  :options="attendeesTypeOptions"
                  option-label="name"
                  option-value="id"
                  style="flex-basis: 20%; min-width: 200px; flex-grow: 0"
              />
              <Button
                  v-tooltip="i18n.$gettext('Remove')"
                  :disabled="isAttendeeAndNotOrganizer"
                  class="p-button-secondary"
                  icon="cil-trash"
                  @click="removeAttendee(attendee.attendee.email)"
              />
            </div>
            <TokenAttachmentList ref="attachmentcontrol" style="display: none" v-model="attachments" @update:modelValue="importICS" />
          </div>
          <div v-if="conferences && conferences.length > 0" class="d-flex align-items-center mb-3">
            <i class="cil cil-video lead font-weight-bold mr-3" />
            <strong class="mb-0"><translate>Conference</translate>:&emsp;</strong>
            <div class="d-flex flex-wrap">
              <Chip
                  v-for="conference in conferences"
                  :key="conference.uri || conference.text || conference.label"
                  :label="conference.label || conference.text || conference.uri"
                  removable
                  @remove="removeConference(conference)"
              />
            </div>
          </div>
          <!--<div class="d-flex flex-wrap" :class="{ 'mb-2': attachments && attachments.length > 0 }">
            <TokenAttachmentList ref="attachmentcontrol" :mimic-big-attachments="true" v-model="attachments" />
            <AttachmentItem
                v-for="a in attachmentsWithFileName"
                :key="JSON.stringify(a)"
                :edit-mode="true"
                @delete="removeAttachment"
                :attachment="a"
            />
          </div>-->
          <div class="flex-shrink-0 flex-grow-1 pb-4" style="min-height: 200px">
            <TipTapTextArea ref="editor" class="w-100 h-100" :placeholder="i18n.$pgettext('leadform', 'Event Description')" />
          </div>

          <Dialog v-model:visible="showModifyRecurrenceDialog" :header="i18n.$gettext('Modify recurring event')">
            <translate>Would you like to modify all events in the series or just this instance?</translate>
            <template #footer>
              <Button class="p-button-text" @click="modifyEvent(true)">
                <translate>This instance</translate>
              </Button>
              <Button @click="modifyEvent(false)">
                <translate>All</translate>
              </Button>
            </template>
          </Dialog>

          <OverlayPanel ref="colorPicker">
            <ColorPicker v-model="color" @update:modelValue="colorPicker.toggle($event)" />
          </OverlayPanel>
        </div>
        <div v-else-if="visibleInternal && event" class="d-flex flex-column w-100 h-100">
          <div
              v-if="event.color"
              class="w-100"
              style="height: .5rem; border-bottom-left-radius: 3px; border-bottom-right-radius: 3px"
              :style="'background:' + event.color"
          />
          <strong v-if="event.status === 'CANCELLED'" :key="event.status" class="mt-4 font-weight-bold text-danger">
            <translate>This event has been cancelled.</translate>
          </strong>
          <div class="d-flex align-items-center mt-4 mb-4">
            <i class="cil-clock lead font-weight-bold mr-3" />
            <strong class="mb-0"><translate>Time</translate>:&emsp;</strong>
            <strong class="mb-0">
              {{ formattedTime }}
            </strong>
          </div>
          <div v-if="timezoneOfEventDiffersFromBrowser" class="d-flex align-items-center mb-4">
            <i class="cil-clock lead font-weight-bold mr-3" />
            <strong class="mb-0"><translate>Original Time</translate>:&emsp;</strong>
            <strong class="mb-0">
              {{ formattedOriginalTime }}
            </strong>
          </div>
          <div v-if="event.location" class="d-flex align-items-center mb-4">
            <i class="cil-location-pin lead font-weight-bold mr-3" />
            <strong class="mb-0"><translate>Location</translate>:&emsp;</strong>
            <strong class="mb-0">
              {{ event.location }}
            </strong>
          </div>
          <div v-if="recurrenceText" class="d-flex align-items-center mb-4">
            <i class="cil-sync lead font-weight-bold mr-3" />
            <strong class="mb-0"><translate>Recurrence</translate>:&emsp;</strong>
            <strong class="mb-0">
              {{ recurrenceText }}
            </strong>
          </div>
          <div v-if="calendarName" class="d-flex align-items-center mb-4">
            <i class="cil-calendar lead font-weight-bold mr-3" />
            <strong class="mb-0"><translate>Calendar</translate>:&emsp;</strong>
            <p class="mb-0">
              {{ calendarName }}
            </p>
          </div>
          <div v-if="event.categories && event.categories.length > 0" class="d-flex align-items-center mb-4">
            <i class="cil-tags lead font-weight-bold mr-3" />
            <strong class="mb-0"><translate>Categories</translate>:&emsp;</strong>
            <div class="d-flex flex-wrap">
              <Chip
                  v-for="category in event.categories"
                  :key="category"
                  :label="category"
                  class="mr-2"
              />
            </div>
          </div>
          <div v-if="event.organizer" class="d-flex align-items-center mb-3">
            <i class="cil-user lead font-weight-bold mr-3" />
            <strong class="mb-0"><translate>Organizer</translate>:&emsp;</strong>
            <Chip
                :key="event.organizer.uri || event.organizer.email"
                v-tooltip="event.organizer.email"
                :label="attendeeName(event.organizer.email, event.organizer.email)"
                :image="attendeeImage(event.organizer.email)"
            />
          </div>
          <div v-if="event.attendees && event.attendees.length > 0" class="d-flex align-items-center mb-3">
            <i class="cil-group lead font-weight-bold mr-3" />
            <strong class="mb-0"><translate>Attendees</translate>:&emsp;</strong>
            <div class="d-flex flex-wrap">
              <Chip
                  v-for="attendee in event.attendees"
                  :key="attendee.uri || attendee.email"
                  v-tooltip="getAttendeeStatusTooltip(attendee)"
                  :label="attendeeName(attendee.email, attendee.name)"
                  :image="attendeeImage(attendee.email)"
                  :class="getChipColor(attendee.status)"
              />
            </div>
          </div>
          <!--<div v-if="event.classification" class="d-flex align-items-center mb-3">
            <i
              class="lead font-weight-bold mr-3"
              :class="{
                'cil-low-vision': event.classification === 'CONFIDENTIAL',
                'cil-eye-slash': event.classification === 'PRIVATE',
                'cil-eye': event.classification === 'PUBLIC'
              }"
            />
            <strong class="mb-0"><translate>Visibility</translate>:&emsp;</strong>
            <p class="mb-0">
              {{
                classificationOptions.find(o => o.id === event.classification)?.name || ''
              }}
            </p>
          </div>
          <div v-if="event.transparency" class="d-flex align-items-center mb-3">
            <i class="cil-calendar-view-day lead font-weight-bold mr-3" />
            <strong class="mb-0"><translate>Availability</translate>:&emsp;</strong>
            <p class="mb-0">
              {{
                freeBusyOptions.find(o => o.id === event.transparency)?.name || ''
              }}
            </p>
          </div>-->
          <div v-if="event.conferences && event.conferences.length > 0" class="d-flex align-items-center mb-3">
            <i class="cil cil-video lead font-weight-bold mr-3" />
            <strong class="mb-0"><translate>Conference</translate>:&emsp;</strong>
            <div class="d-flex flex-wrap">
              <Chip
                  v-for="conference in event.conferences"
                  :key="conference.uri || conference.text || conference.label"
                  :label="conference.label || conference.text"
                  icon=""
              >
                <a v-if="conference.uri" :href="conference.uri" target="_blank">{{ conference.uri }}</a>
              </Chip>
            </div>
          </div>
          <div v-if="event.attachments && event.attachments.length > 0" class="d-flex align-items-center mb-2">
            <i class="cil-file lead font-weight-bold mr-3" />
            <strong class="mb-0"><translate>Files</translate>:&emsp;</strong>
          </div>
          <div v-if="event.attachments && event.attachments.length > 0" class="d-flex align-items-center mb-2">
            <AttachmentItem v-for="a in attachmentsWithFileName" :key="JSON.stringify(a)" :attachment="a" />
          </div>
          <div v-if="event.description" class="mb-3">
            <p>
              <i class="cil-info lead font-weight-bold mr-3" />
              <strong><translate>Description</translate>:&emsp;</strong>
            </p>
          </div>
          <div v-if="event.description" class="flex-shrink-0 flex-grow-1 pb-4" style="min-height: 200px">
            <TipTapTextArea
                ref="editor"
                class="w-100 h-100"
                :editable="false"
                :show-tool-bar="false"
                :preview-content="event.description"
                @changed="saveEventListItemChanges()"
            />
          </div>
          <div v-if="isAttendee && event.status !== 'CANCELLED'">
            <div class="d-flex">
              <Button
                  v-if="myAttendeeStatus !== Participation_DECLINED"
                  class="p-button-raised p-button-danger mx-2 mb-2"
                  :loading="handlingIMIPLoading"
                  @click="setParticipationState(Participation_DECLINED)"
              >
                <translate>Decline</translate>
              </Button>
              <Button
                  v-if="myAttendeeStatus !== Participation_TENTATIVE"
                  class="p-button-raised p-button-warning mr-2 mb-2"
                  :loading="handlingIMIPLoading"
                  @click="setParticipationState(Participation_TENTATIVE)"
              >
                <translate>Tentative</translate>
              </Button>
              <Button
                  v-if="myAttendeeStatus !== Participation_ACCEPTED"
                  class="p-button-raised p-button-success mb-2"
                  :loading="handlingIMIPLoading"
                  @click="setParticipationState(Participation_ACCEPTED)"
              >
                <translate>Accept</translate>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Dialog
        v-model:visible="uploadingICS"
        :header="i18n.$gettext('Uploading ICS file...')"
        :modal="true"
        :closable="false"
        :draggable="false"
    >
      <div class="w-100 d-flex flex-column align-items-center">
        <p>{{ uploadingAttachment.progress }}%</p>
        <ProgressSpinner />
      </div>
    </Dialog>
    <!--<Menu ref="attachMenu" :model="attachMenuItems" :popup="true" />-->
  </Dialog>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component"
import CalendarEvent from "../../model/entry/Event"
import AnimatedInput from "../common/AnimatedInput.vue"
import LoadingButton from "@/components/common/LoadingButton.vue"
import {Watch} from "vue-property-decorator"
import CalendarPicker from "primevue/calendar"
import {calendarServiceApi} from "@/api/CalendarServiceApi"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Attendee from "@/model/common/caldav/Attendee"
import Alarm from "@/model/common/caldav/Alarm"
import Conference from "@/model/common/caldav/Conference"
import {eventServiceApi} from "@/api/EventServiceApi"
import InputSwitch from "primevue/inputswitch"
import { ref } from "@vue/reactivity"
import InfiniteList from "@/components/common/InfiniteList.vue"
import User from "@/model/User"
import Calendar from "@/model/directory/Calendar"
import Avatar from "@/components/common/Avatar.vue"
import {userServiceApi} from "@/api/UserServiceApi"
import UserPicker from "@/components/common/UserPicker.vue"
import DatePicker from "@/components/common/DatePicker.vue"
import Dropdown from "@/components/common/Dropdown.vue"
import RecurrencePicker from "@/components/common/RecurrencePicker.vue"
import Organizer from "@/model/common/caldav/Organizer"
import {rpcClient} from "@/api/WebsocketClient"
import RecurrenceRule from "@/model/common/caldav/RecurrenceRule"
import ColorPicker from "@/components/common/ColorPicker.vue"
import OverlayPanel from "primevue/overlaypanel"
import Dialog from "primevue/dialog"
import Button from "primevue/button"
import AutoComplete from "@/components/common/AutoComplete.vue"
import EmailUtil from "@/util/EmailUtil"
import RpcError from "@/api/RpcError"
import TokenAttachmentList from "@/components/common/TokenAttachmentList.vue"
import useToast from "@/util/toasts"
import {useConfirm} from "primevue/useconfirm"
import DateTimeUtil from "@/util/DateTimeUtil"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import CalDavFile from '@/model/common/caldav/File'
import Chip from 'primevue/chip'
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService"
import Attachment from "@/model/common/caldav/Attachment"
import dayjs from "@/util/dayjs"
import breakpointUtil from "@/util/BreakpointUtil"
import TipTapTextArea from "@/components/common/TipTapTextArea.vue"
import Menu from "primevue/menu"
import featureSubset from "@/util/FeatureSubsets"
import {eventStore} from "@/store/EventStore"
import AttachmentItem from "@/components/common/AttachmentItem.vue"
import AttachmentUpload from "@/util/AttachmentUpload"
import Contact from "@/model/entry/Contact"
import StructuredNameField from "@/components/contacts/subcomponents/StructuredNameField.vue"
import StructuredName from "@/model/common/carddav/StructuredName"
import Organization from "@/model/common/carddav/Organization"
import Address from "@/model/common/carddav/Address"
import AddressField from "@/components/contacts/subcomponents/AddressField.vue"
import EmailField from "@/components/contacts/subcomponents/EmailField.vue"
import EmailAddress from "@/model/common/EmailAddress"
import Telephone from "@/model/common/carddav/Telephone"
import PhoneField from "@/components/contacts/subcomponents/PhoneField.vue"
import {salesServiceApi} from "@/api/SalesServiceApi"
import {timeZoneUtil} from "@/util/timeZoneUtil"
import ProgressSpinner from "primevue/progressspinner"
import TextValue from "@/model/common/carddav/TextValue"
import Categories from "@/model/common/carddav/Categories"

@Options({
  name: "LeadWithMeetingForm",
  components: {
    //@ts-ignore
    AnimatedInput, LoadingButton, Dialog, CalendarPicker, InputSwitch, InfiniteList, Avatar, UserPicker,
    Dropdown, RecurrencePicker, ColorPicker, OverlayPanel, TokenAttachmentList, DatePicker, Button, ProgressSpinner,
    AutoComplete, Chip, TipTapTextArea, Menu, AttachmentItem, StructuredNameField, AddressField, EmailField, PhoneField
  },
  //@ts-ignore
  props: {
    event: [Object, CalendarEvent],
    selectedCalendars: Array
  },
  emits: [
    'hide'
  ]
})
export default class LeadWithMeetingForm extends Vue {

  rpcClient = rpcClient
  featureSubset = featureSubset

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()

  //@ts-ignore
  colorPicker: OverlayPanel = ref<OverlayPanel>(null)
  //@ts-ignore
  editor: TipTapTextArea = ref<TipTapTextArea>(null)
  //@ts-ignore
  notes: TipTapTextArea = ref<TipTapTextArea>(null)
  //@ts-ignore
  description: HTMLElement = ref<HTMLElement>(null)

  saveLoading = false;

  event!: CalendarEvent | null
  contact: Contact = Object.assign(new Contact(), {
    structuredName: new StructuredName(),
    organization: Object.assign(new Organization(), { titles: [''] }),
    addresses: [
        Object.assign(new Address(), { types: ['work'] })
    ],
    emailAddresses: [
        Object.assign(new EmailAddress(), { types: ['work'] })
    ],
    phones: [
      Object.assign(new Telephone(), { types: ['voice'] }),
      Object.assign(new Telephone(), { types: ['cell'] })
    ]
  })
  visibleInternal = false
  editMode = false

  get sourceOptions():  { label: string, value: string }[] {
    let options : { label: string, value: string }[] = this._sourceOptions.filter((option: { label: string, value: string, users: string[] } ) => {
      return option.users && rpcClient?.session?.user?.userName && option.users.includes(rpcClient.session.user.userName)
    }) || []

    if (!options || options.length == 0) {
       options = this._sourceOptions
    }

    return options
  }

  get hasOnlyOneSourceOption(): boolean {
    return this.sourceOptions.length == 1
  }

  _sourceOptions: { label: string, value: string, users: string[] }[] = [

  ]
  campaignOptions: { label: string, value: string }[] = [

  ]

  source: string = ''
  campaign: string = ''

  showModifyRecurrenceDialog = false
  handlingIMIPLoading: boolean = false
  internal_showTimezonesSelector: boolean = false

  newParticipant = ""
  selectableUsers: string[] = []

  timeZone: string = ""
  browserDefaultTimeZone: string = ""
  start: Date | null = null
  end: Date | null = null
  summary: string | null = null
  location: string | null = null
  calendarId: string | null = null
  allDay = false
  classification = 'PRIVATE'
  priority = 'UNDEFINED'
  organizer: Organizer | null = null
  categories: string[] = []
  attendees: { attendee: Attendee, user: User | undefined }[] = []
  contacts: string[] = []
  alarms: Alarm[] = []
  color: string | null = null
  conferences: Conference[] = []
  recurring = false
  transparency = 'OPAQUE'
  recurrenceRule: RecurrenceRule = Object.assign(new RecurrenceRule(), {
    frequency: 'WEEKLY',
    interval: '1'
  })

  //@ts-ignore
  attachmentcontrol: TokenAttachmentList = ref<TokenAttachmentList | null>(null)
  //@ts-ignore
  //attachMenu: Menu = ref(null)
  attachments: AttachmentUpload[] = []
  uploadingICS: boolean = false
  uploadingAttachment: AttachmentUpload | null = null

  /*attachMenuItems = [
    {
      label: this.i18n.$gettext('Upload from your computer'),
      icon: 'cil-data-transfer-up',
      command: () => {
        this.attachmentcontrol.openNativeFileChooser()
      }
    },
    {
      label: this.i18n.$gettext('Choose from files'),
      icon: 'cil-inbox-out',
      command: () => {
        this.attachmentcontrol.openInodeChooser()
      }
    },
  ]*/

  selectedCalendars: string[] = []

  get userEmail(): string | null {
    return rpcClient.session?.user?.email || null
  }

  get isNewEvent(): boolean {
    return Boolean(!this.event?.originalId)
  }

  get isAttendee(): boolean {
    if (this.isNewEvent || !this.userEmail || !this.attendees) return false
    return Boolean(this.attendees.find(attendee => attendee.attendee.email === this.userEmail))
  }

  get isAttendeeAndNotOrganizer(): boolean {
    return this.isAttendee && this.organizer?.email !== this.userEmail
  }

  getChipColor(status: string): string {
    let classString: string = "mr-2"
    if (status === this.Participation_ACCEPTED) classString += " text-success"
    else if (status === this.Participation_TENTATIVE) classString += " text-info"
    else classString += " text-danger"
    return classString
  }

  getAttendeeStatus(status: string | null): string {
    let participation: any | null = this.participationOptions.find(o => o.id === status)
    if (!participation) {
      participation = this.participationOptions.find(o => o.id === this.Participation_NEEDS_ACTION)
    }
    return participation.name
  }

  Participation_NEEDS_ACTION: string = "NEEDS_ACTION"
  Participation_ACCEPTED: string = "ACCEPTED"
  Participation_DECLINED: string = "DECLINED"
  Participation_TENTATIVE: string = "TENTATIVE"
  Participation_DELEGATED: string = "DELEGATED"

  participationOptions: any[] = [
    {
      id: this.Participation_NEEDS_ACTION,
      name: this.i18n.$gettext('Needs Action')
    },
    {
      id: this.Participation_ACCEPTED,
      name: this.i18n.$gettext('Accepted')
    },
    {
      id: this.Participation_DECLINED,
      name: this.i18n.$gettext('Declined')
    },
    {
      id: this.Participation_TENTATIVE,
      name: this.i18n.$gettext('Tentative')
    },
    {
      id: this.Participation_DELEGATED,
      name: this.i18n.$gettext('Delegated')
    }
  ]

  get myAttendeeStatus(): string {
    if (!this.attendees) return this.Participation_NEEDS_ACTION
    return this.attendees.find(attendee => attendee?.attendee?.email === this.userEmail)?.attendee?.status || this.Participation_NEEDS_ACTION
  }

  filterUsers(event: any) {
    let users = this.users
    return this.selectableUsers = users.filter((user: User) => {
      const query: string = event.query.toLowerCase()
      return user.userName?.toLowerCase()?.indexOf(query) !== -1 ||
        user.displayName?.toLowerCase()?.indexOf(query) !== -1 ||
        user.email?.toLowerCase()?.indexOf(query) !== -1 ||
        user.uid?.toLowerCase()?.indexOf(query) !== -1
    }).map((user: User) => {
      let userTag: string = ""
      if (user.email) userTag = user.email
      if (user.displayName) userTag = user.displayName + " <" + userTag + ">"
      return userTag
    }).filter((str: string) => {return str !== "" })
  }

  saveEventListItemChanges() {
    if (this.event?.originalId && !this.saveLoading && this.canEdit && this.editor.hasChanges()) {
      this.event.description = this.editor.getMarkdown()
      this.saveLoading = true
      return eventServiceApi._updateEvent(this.event).then((id: string) => {
        return id
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Updating event failed"))
      }).finally(() => {
        this.saveLoading = false
      })
    }
  }

  addOrUpdateAttendee() {
    if (!this.attendees) {
      this.attendees = []
    }
    if (this.contact.emailAddresses?.length) {
      const email: EmailAddress = this.contact.emailAddresses[0]
      let attendee: Attendee | undefined = this.attendees.find(a => a.attendee.email === email.address)?.attendee
      if (attendee) {
        attendee.name = ((this.contact.structuredName?.given || '') + ' ' + (this.contact.structuredName?.family || '')).trim()
      } else {
        attendee = this.attendees.find(a => a.attendee.uri === email.alternativeId)?.attendee
        if (attendee) {
          attendee.email = email.address
          attendee.name = ((this.contact.structuredName?.given || '') + ' ' + (this.contact.structuredName?.family || '')).trim()
        } else {
          email.alternativeId = Math.random().toString(36).substr(2, 5)
          attendee = Object.assign(new Attendee(), { email: email.address, uri: email.alternativeId, participationLevel: 'REQUIRED' })
          attendee.name = ((this.contact.structuredName?.given || '') + ' ' + (this.contact.structuredName?.family || '')).trim()
          this.attendees.push({ attendee: attendee, user: undefined })
        }
      }
    }
  }

  toggleEditMode(): void {
    if (this.editMode) {
      this.start = this.event?.start ? new Date(this.event.start) : null
      this.end = this.event?.end ? new Date(this.event.end) : null
    } else if (this.allDay) {
      this.timeZone = this.browserDefaultTimeZone
      this.showTimezonesSelector = false
    } else {
      const editTimezone = this.safeGetTzId(this.timeZone)
      this.showTimezonesSelector = editTimezone !== this.browserDefaultTimeZone
      if (this.start && !!editTimezone) {
        this.start = this.shiftDateIntoTargetTimezone(this.start, editTimezone)
      }
      if (this.end && !!editTimezone) {
        this.end = this.shiftDateIntoTargetTimezone(this.end, editTimezone)
      }
    }
    this.editMode = !this.editMode
  }

  save(): Promise<string | void> {

    let hasIncompleteUploads: boolean = this.attachmentcontrol.checkForIncompleteUploads()
    if (hasIncompleteUploads){
      this.toast.error(this.i18n.$gettext("You cannot save the event while attachments are still uploading"))
      return Promise.reject()
    }

    if (!this.source || !this.campaign){
      this.toast.error(this.i18n.$gettext("You need to specify source and campaign"))
      return Promise.reject()
    }

    if (!this.start || !this.end){
      this.toast.error(this.i18n.$gettext("You need to specify start and end"))
      return Promise.reject()
    }

    if (!this.summary || this.summary === ""){
      this.toast.error(this.i18n.$gettext("You need to specify a summary"))
      return Promise.reject()
    }

    if(!this.calendarId) {
      let calendarIds: string[] = this.selectedCalendars.filter((s: string) => {
        return s && s !== ""
      })
      this.calendarId = calendarIds[0]
    }

    if (!this.calendarId){
      this.toast.error(this.i18n.$gettext("You need to select a calendar"))
      return Promise.reject()
    }

    if (this.event?.originalId && this.event.recurrenceRule && this.event.start &&
      DateTimeUtil.isSameDay(new Date(this.event.start), this.getDateFromTargetTimezone(this.start, this.timeZone))) {
      this.showModifyRecurrenceDialog = true
      return Promise.resolve()
    } else {
      return this.modifyEvent(Boolean(this.event?.originalId && this.event.recurrenceRule))
    }
  }

  modifyEvent(modifyInstance: boolean): Promise<string | void> {
    this.showModifyRecurrenceDialog = false
    if (this.validate && this.calendarId) {
      const event = this.event ? Object.assign(new CalendarEvent(), this.event) : new CalendarEvent()
      if (modifyInstance) {
        this.recurring = false
        event.recurrenceRule = null
        event.recurrenceId = {
          start: event.start,
          range: null
        }
      }
      if (this.allDay) {
        if (this.end) this.end = dayjs(this.end).endOf('day').add(1, 'ms').toDate()
        if (this.start) this.start = dayjs(this.start).startOf('day').toDate()
      }
      const shiftedStart: Date | null = this.start ? this.getDateFromTargetTimezone(this.start, this.timeZone) : null
      const shiftedEnd: Date | null = this.end ? this.getDateFromTargetTimezone(this.end, this.timeZone) : null
      if (shiftedStart && this.recurring && this.recurrenceRule) {
        if (!event.start || shiftedStart.getTime() != Date.parse(event.start)) {
          //TODO Handle this better => Does the user want to change the start of the series, or just the time of day?
          event.start = shiftedStart.toISOString()
        }
        const duration = shiftedEnd ? shiftedEnd.getTime() - shiftedStart.getTime() : 86400000
        event.duration = DateTimeUtil.getDuration(duration)
        event.end = null
      } else {
        if (shiftedStart) {
          event.start = shiftedStart.toISOString()
        }
        if (this.end) {
          event.end = this.getDateFromTargetTimezone(this.end, this.timeZone).toISOString()
          event.duration = null
        }
      }
      event.timeZoneId = this.timeZone
      event.summary = this.summary
      event.location = this.location
      event.originalParentId = this.calendarId
      event.allDay = this.allDay
      event.classification = this.classification
      event.description = this.editor.getMarkdown()
      event.priority = this.priority
      event.organizer = this.organizer ? this.organizer : (rpcClient.session.user ? Object.assign(new Organizer(), {
        email: rpcClient.session.user.email,
        name: rpcClient.session.user.displayName || rpcClient.session.user.userName,
      }) : null)
      event.categories = [...this.categories]
      event.attendees = (this.attendees || []).map(a => a.attendee)
      for (let attendee of event.attendees) {
        attendee.rsvp = true //TODO
        attendee.uri = null
      }
      event.contacts = this.contacts
      event.alarms = this.alarms
      event.color = this.color
      event.conferences = this.conferences
      event.recurrenceRule = this.recurring ? this.recurrenceRule : null
      event.instanceDates = event.originalId ? eventStore.state.events.get(event.originalId)?.instanceDates || null : null

      event.fileTokens = this.attachmentcontrol.getFileTokens()

      this.saveLoading = true

      if (this.event?.originalId && !modifyInstance) {
        return eventServiceApi._updateEvent(event).then((id: string) => {
          if (this.editMode) {
            this.toggleEditMode()
          }
          this.attachments = []
          return id
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Updating event failed"))
        }).finally(() => {
          this.saveLoading = false
        })
      } else {
        this.contact.notes = []
        this.contact.notes[0] = Object.assign(new TextValue(), { text: this.notes.getMarkdown() })
        this.contact.notes[1] = Object.assign(new TextValue(), { text: this.notes.getHTML() })
        this.contact.categories = []
        this.contact.categories[0] = Object.assign(new Categories(), { names: [ this.source ] })
        this.contact.categories[1] = Object.assign(new Categories(), { names: [ this.campaign ] })
        return salesServiceApi._createLead(event, this.contact).then(() => {
          this.hide()
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Creating Appointment failed"))
        }).finally(() => {
          this.saveLoading = false
        })
      }
    } else {
      this.toast.error(this.i18n.$gettext("Start and end dates must be set."))
      return Promise.reject()
    }
  }

  get validate(): boolean {
    return Boolean(this.start && (this.end || this.allDay) && this.summary)
  }

  classificationOptions: any[] = [
    {
      id: 'PUBLIC',
      name: this.i18n.$gettext('Public')
    },
    {
      id: 'PRIVATE',
      name: this.i18n.$gettext('Private')
    },
    {
      id: 'CONFIDENTIAL',
      name: this.i18n.$gettext('Confidential')
    }
  ]

  priorityOptions: string[] = [
    'UNDEFINED',
    'HIGH',
    'MEDIUM',
    'LOW'
  ]

  attendeesTypeOptions: any[] = [
    {
      id: 'OPTIONAL',
      name: this.i18n.$gettext('Optional')
    },
    {
      id: 'REQUIRED',
      name: this.i18n.$gettext('Required')
    },
    {
      id: 'FYI',
      name: this.i18n.$gettext('Fyi')
    }
  ]

  freeBusyOptions: any[] = [
    {
      id: 'OPAQUE',
      name: this.i18n.$gettext('Busy')
    },
    {
      id: 'TRANSPARENT',
      name: this.i18n.$gettext('Free')
    }
  ]

  filterCategoriesOptions(event: any): void {

  }

  get categoriesOptions(): string[] {
    return []
  }

  hide() {
    this.attachments = []
    this.$emit('hide')
  }

  confirmClose() {
    if (this.editMode && this.hasChanges) {
      this.confirm.require({
        message: this.i18n.$gettext('Do you want to save the event?'),
        header: this.i18n.$gettext('Save & Close'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.save().then(() => {
            this.toast.success(this.i18n.$gettext("Event saved"))
            this.hide()
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Saving event failed"))
          })
        },
        reject: () => {
          this.hide()
        }
      })
    } else {
      this.hide()
    }
  }

  get calendars(): Calendar[] {
    return calendarServiceApi.getCalendars().data || []
  }

  get writableCalendars(): Calendar[] {
    const canWrite: string[] = ['WRITE', 'OWNER']
    return this.calendars.filter(c => canWrite.includes(c.shareAccess || '') || c.originalId === this.event?.originalParentId)
  }

  get canEdit(): boolean {
    const canWrite: string[] = ['WRITE', 'OWNER']
    const calendar: Calendar | undefined = this.calendars?.find(c => c.originalId === this.event?.originalParentId)
    return Boolean(calendar && canWrite.includes(calendar.shareAccess || ''))
  }

  get users(): User[] {
    return userServiceApi.getUsers().data || []
  }

  get formattedTime(): string {
    if (this.event?.start) {
      const startString = this.event.allDay ? dayjs(this.event.start).format('dddd, L') : dayjs(this.event.start).format('dddd, LLL')
      const start = new Date(this.event.start)
      let end: Date | null = null
      if (this.event.duration) {
        end = new Date(start.getTime() + DateTimeUtil.getMillisFromDuration(this.event.duration))
      } else if (this.event.end) {
        end = new Date(this.event.end)
      }
      const allDayEnd = end ? dayjs(end).add(-1, 'day').toDate(): null
      const sameDay: boolean = DateTimeUtil.isSameDay(start, this.event.allDay ? allDayEnd : end)
      if (!end || (sameDay && this.event.allDay)) {
        return startString
      } else if (end && sameDay) {
        return startString + ' - ' + dayjs(end).format('LT')
      } else if (end && this.event.allDay) {
        return startString + ' - ' + dayjs(end).add(-1, 'day').format('dddd, L')
      } else {
        return startString + ' - ' + dayjs(end).format('dddd, LLL')
      }
    } else {
      return ''
    }
  }

  get recurrenceText(): string {
    if (this.event?.recurrenceRule) {
      try {
        return DateTimeUtil.getRRule(this.event, this.event.recurrenceRule)?.toText() || this.i18n.$gettext("Could not determine recurrence")
      } catch (ignore) {
        console.debug(ignore)
        return ''
      }
    } else {
      return ''
    }
  }

  get calendarName(): string {
    if (this.event?.originalParentId) {
      return this.calendars?.find(c => c.originalId === this.event?.originalParentId)?.name || ''
    } else {
      return ''
    }
  }

  get attachmentsWithFileName(): Attachment[] {
    return this.event?.attachments?.filter(a => a.file && a.file.fileName) || []
  }

  attendeeImage(email: string): string | null {
    const user: User | undefined = this.users.find(u => u.email === email)
    if (user) {
      const image: CachedImage = imageLoadingService.getCachedImage(`/salestool-api/v1/users/${user.userName}/picture`)
      return image.error ? null : image.cached
    } else {
      return null
    }
  }

  attendeeName(email: string, name: string): string {
    const user: User | undefined = this.users.find(u => u.email === email)
    if (user) {
      return user.displayName || ((user.givenname || '') + (user.surname || '')) || email
    } else {
      return name ? name : email
    }
  }

  getAttendeeStatusTooltip(attendee: Attendee | null): string {
    if (!attendee) return ''
    let tooltip: string = this.attendeesTypeOptions.find(o => o.id === attendee.participationLevel)?.name || ''
    if (tooltip)
      tooltip += ': '
    tooltip += this.getAttendeeStatus(attendee.status)

    return tooltip
  }

  addAttendee() {
    const parts: string[] = this.newParticipant.split(" <")
    if (parts.length < 1) return
    const email = parts[parts.length - 1].replace('>', '')

    if (email === "") {
      return
    } else if (!!this.attendees.find(a => a.attendee.email === email)) {
      this.newParticipant = ""
      return
    } else if (EmailUtil.isValidEmail(email)) {
      const attendee: Attendee = new Attendee()
      const user: User | undefined = this.users.find(u => u.email === email)
      //Email needs to be the DAV principal for local users so the dav server can deliver the message locally
      attendee.email = (user && user.email) ? user.email : email
      attendee.name = (user && user.displayName) ? user.displayName : email
      attendee.participationLevel = 'REQUIRED'
      this.attendees.push({
        attendee: attendee,
        user: user
      })
      this.newParticipant = ""
    } else {
      this.toast.error(this.i18n.$gettext("Attendee must be an email address"))
    }
  }

  addOrRemoveVideoConference() {
    if (this.conferences && this.conferences.length > 0) {
      this.removeConference(this.conferences[0])
    } else if (this.featureSubset.conferenceUrl) {
      if (!this.conferences) {
        this.conferences = []
      }
      let uri = this.featureSubset.conferenceUrl
      if (!uri.endsWith('/')) {
        uri += '/'
      }
      for (let i = 0; i < 3; i++) {
        uri += Math.random().toString(36).substr(2)
      }
      const conference: Conference = new Conference()
      conference.uri = uri
      this.conferences.push(conference)
      this.editor.insertContent('<p>Meeting-Link: <a href="' + uri + '"></a>' + uri + '</p>')
    }
  }

  removeConference(conference: any) {
    const i: number = this.conferences.indexOf(conference)
    if (i >= 0) {
      this.conferences.splice(i, 1)
      let html = this.editor?.getHTML() || ''
      html = html.replace(/<p>Meeting-Link:.*<\/p>/, '')
      if (html && html.trim() !== '') {
        this.editor.setContent(html)
      } else if (this.editor) {
        this.editor.clearContent()
      }
    }
  }

  removeAttendee(email: string) {
    this.attendees = this.attendees.filter(attendee => attendee.attendee.email !== email)
  }

  downloadAttachment(file: CalDavFile) {
    if (file.uri) {
      window.open(file.uri, '_blank')
    } else if (file.fileName) {
      window.open('/salestool-api/v1/uploads/' + encodeURIComponent(file.fileName), '_blank')
    }
  }

  getShortName(name: string): string {
    return name.length > 15 ? name.substring(0,15) + "..." : name
  }

  removeAttachment(attachment: any) {
    if (this.event?.attachments) {
      const index: number = this.event.attachments.indexOf(attachment)
      if (index >= 0) {
        this.event.attachments.splice(index, 1)
      }
    }
  }

  setParticipationState(status: string) {
    if(this.event && status) {
      this.handlingIMIPLoading = true
      eventServiceApi._setMyParticipationState(this.event, status).then(() => {
        if (status === this.Participation_ACCEPTED) this.toast.success(this.i18n.$gettext('Your new attendee status is accepted'))
        if (status === this.Participation_DECLINED) this.toast.success(this.i18n.$gettext('Your new attendee status is declined'))
        if (status === this.Participation_TENTATIVE) this.toast.success(this.i18n.$gettext('Your new attendee status is tentative'))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Failed to update participation"))
      }).finally(() => {
        this.handlingIMIPLoading = false
      })
    } else{
      this.toast.error(this.i18n.$gettext("Invalid scheduling request"))
    }
  }

  watchStart() {
    if (this.start && (!this.end || this.start >= this.end)) {
      console.log(this.start)
      this.end = new Date(this.start.getTime() + 3600000) //Add an hour to the end
    }
  }

  watchEnd() {
    if (this.start && (!this.end || this.start > this.end)) {
      this.end = new Date(this.start.getTime() + 60000) //Add a minute to the end
    }
  }

  get hasChanges(): boolean {
    if (this.event?.originalId) {
      return Boolean(((!this.event?.start && this.start) || (this.start && this.start.getTime() !== new Date(this.event?.start || 0).getTime())) ||
        ((!this.event?.end && this.end) || (this.end && this.end.getTime() !== new Date(this.event?.end || 0).getTime())) ||
        (this.calendarId !== this.event?.originalParentId) ||
        (this.summary !== this.event?.summary) ||
        (this.location !== this.event?.location) ||
        (this.classification !== (this.event?.classification || 'PRIVATE')) ||
        //TODO (this.description !== this.event?.description) ||
        (this.priority !== (this.event?.priority || 'UNDEFINED')) ||
        (this.color !== this.event?.color) ||
        //(this.recurrenceRule !== this.event?.recurrenceRule) ||
        (this.organizer !== this.event?.organizer) ||
        (this.transparency !== (this.event?.transparency || 'OPAQUE')) ||
        !SortAndFilterUtil.arrayEquals(this.categories, this.event?.categories || []) ||
        !SortAndFilterUtil.arrayEquals((this.attendees || []).map(a => a.attendee), this.event?.attendees || []) ||
        !SortAndFilterUtil.arrayEquals(this.contacts, this.event?.contacts || []) ||
        !SortAndFilterUtil.arrayEquals(this.alarms, this.event?.alarms || []) ||
        !SortAndFilterUtil.arrayEquals(this.conferences, this.event?.conferences || []))
    } else {
      return Boolean(this.event && this.validate)
    }
  }

  @Watch('editor')
  watchEditor(newEditor: TipTapTextArea, oldEditor: TipTapTextArea) {
    if (this.event?.description && this.event.description !== '' && this.editor) {
      this.editor.setContent(this.event.description)
    } else if (this.editor) {
      this.editor.clearContent()
    }
  }

  @Watch('event')
  watchEvent(event: CalendarEvent | null, oldValue: CalendarEvent | null) {
    if (!event) {
      this.visibleInternal = false
      this.editMode = false
      this.start = null
      this.end = null
      this.summary = null
      this.location = null
      this.calendarId = null
      this.allDay = false
      this.classification = 'PRIVATE'
      if (this.editor) {
        this.editor.clearContent()
      }
      this.priority = 'UNDEFINED'
      this.organizer = null
      this.categories = []
      this.attendees = []
      this.contacts = []
      this.alarms = []
      this.color = null
      this.conferences = []
      this.recurring = false
      this.transparency = 'OPAQUE'
      this.recurrenceRule = Object.assign(new RecurrenceRule(), {
        frequency: 'WEEKLY',
        interval: '1'
      })
    } else if (!this.editMode) {
      this.visibleInternal = true

      this.allDay = Boolean(event.allDay)
      this.timeZone = this.allDay ? this.browserDefaultTimeZone : (event.timeZoneId || this.browserDefaultTimeZone)
      this.start = event.start ? new Date(event.start) : null
      if (this.editMode && this.start) {
        //Shift this in target timezone when updated in editmode
        this.start = this.shiftDateIntoTargetTimezone(this.start, this.timeZone)
      }
      if (event.end) {
        this.end =  event.allDay ? dayjs(event.end).add(-1, 'day').toDate() : new Date(event.end)
        if (this.editMode) {
          this.end = this.shiftDateIntoTargetTimezone(this.end, this.timeZone)
        }
      } else if (this.start && event.duration) {
        this.end = new Date(this.start.getTime() + DateTimeUtil.getMillisFromDuration(event.duration) - (event.allDay ? 1 : 0))
      }
      this.summary = event.summary
      this.location = event.location
      if (event.originalParentId) {
        this.calendarId = event.originalParentId
      } else {
        this.calendarId = this.calculateFirstWritableAndSelectedCalendar()
      }
      this.classification = event.classification || 'PRIVATE'
      if (event.description && this.editor) {
        this.editor.setContent(event.description)
      }
      this.priority = event.priority || 'UNDEFINED'
      this.transparency = event.transparency || 'OPAQUE'
      if (event.organizer) {
        this.organizer = event.organizer
      } else {
        this.organizer = null
      }
      if (event.categories) {
        this.categories = event.categories
      } else {
        this.categories = []
      }
      if (event.attendees) {
        this.attendees = event.attendees.map(a => {
          return {
            attendee: a,
            user: this.users.find(u => u.email === a.email)
          }
        })
      } else {
        this.attendees = []
      }
      if (event.contacts) {
        this.contacts = event.contacts
      } else {
        this.contacts = []
      }
      if (event.alarms) {
        this.alarms = event.alarms
      } else {
        this.alarms = []
      }
      this.color = event.color
      if (event.conferences) {
        this.conferences = event.conferences
      } else {
        this.conferences = []
      }
      if (event.recurrenceRule) {
        this.recurring = true
        this.recurrenceRule = event.recurrenceRule
      } else {
        this.recurring = false
      }
      if (!event.originalId) {
        this.toggleEditMode()
      }
    }
  }

  get modalStyle() {
    if (breakpointUtil.isOnMobile()) {
      return { width: "100%", margin: "0", height: "100% !important", maxHeight: "100%" }
    } else {
      return { width: "1200px", maxWidth: "100%", margin: "1rem", height: "100%" }
    }
  }

  calculateFirstWritableAndSelectedCalendar(): string | null {
    let allCalendars: Calendar[] = this.writableCalendars
    for (let calendar of allCalendars) {
      if(this.selectedCalendars.length == 0) {  //if no calendar is selected, use the first one of the available
        return calendar.originalId
      } else {
        if(calendar.originalId && this.selectedCalendars.indexOf(calendar.originalId) > -1){
          return calendar.originalId
        }
      }
    }
    return null
  }

  @Watch('visibleInternal')
  fillInDefaultCalendar(){
    if (this.event && this.event.originalParentId) {
      this.calendarId = this.event.originalParentId
    } else {
      this.calendarId = this.calculateFirstWritableAndSelectedCalendar()
    }
  }

  mounted() {
    salesServiceApi._getSources().then((sources: { label: string, value: string, users: string[] }[]) => {
      this._sourceOptions = sources
      if(this.source == '') {
        this.source = this._sourceOptions[0]?.value || ''
      }
    })
    salesServiceApi._getCampaigns().then(campaigns => this.campaignOptions = campaigns)
    this.browserDefaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    this.watchEvent(this.event, null)
    this.$nextTick(() => {
      //Get First selected Calendar:
      let calendarIds: string[] = this.selectedCalendars.filter((s: string) => {
        return s && s !== ""
      })
      if (calendarIds.length > 0) {
        this.calendarId = calendarIds[0]
      }
    })
  }

  importICS() {
    if (this.attachments?.length && this.attachments[0].promise) {
      const attachment = this.attachments[0]
      this.uploadingICS = true
      this.uploadingAttachment = attachment
      attachment.promise?.then(() => {
        this.uploadingAttachment = null
        salesServiceApi._convertICSToEvents(attachment.handle).then((events: CalendarEvent[]) => {
          if (events.length) {
            if (!this.notes.hasChanges()) {
              this.notes.setContent(events[0].description || undefined)
              events[0].description = ''
            }
            Object.assign(this.event, events[0])
            this.editMode = false
            this.watchEvent(this.event, null)
          } else {
            this.toast.error(this.i18n.$gettext("Events could not be imported from .ics file."))
          }
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Events could not be imported from .ics file."))
        }).finally(() => {
          this.uploadingICS = false
        })
      })
      this.attachments = []
    }
  }

  safeGetTzId(tzId: string | null): string {
    const defaultValue: string = this.browserDefaultTimeZone || Intl.DateTimeFormat().resolvedOptions().timeZone

    //handle default first:
    if (tzId === null) {
      return defaultValue
    }

    if (this.browserSupportsTimeZoneId(tzId)) {
      return tzId
    }

    let result: string = defaultValue

    timeZoneUtil.timezoneEquivalents.forEach((equivalentList: string[]) => {
      if(equivalentList.indexOf(tzId) > -1 && equivalentList.length > 0){
        equivalentList.forEach((tzEquivalent: string) => {
          if(this.browserSupportsTimeZoneId(tzEquivalent)){
            result = tzEquivalent
          }
        })
      }
    })

    //Default:
    return result
  }

  shiftDateIntoTargetTimezone(dateToShift: Date, tzId: string) : Date {
    let shiftedInWrongDirection : Date =  dayjs(dateToShift).tz(tzId, true).toDate()
    let diff = dateToShift.getTime() - shiftedInWrongDirection.getTime()
    let date = new Date(dateToShift.getTime() + diff)
    return this.applyDSTOffset(date, false)
  }

  getDateFromTargetTimezone(dateToShift: Date, tzId: string) : Date {
    let date = dayjs(dateToShift).tz(tzId, true).toDate()
    return this.applyDSTOffset(date, true)
  }

  applyDSTOffset(dateToShift: Date, fromTargetTZ: boolean): Date {
    let currentOffset = (new Date()).getTimezoneOffset()
    let offset = dateToShift.getTimezoneOffset()
    let difference = currentOffset - offset
    if (difference == 0) return dateToShift //Nothing to do

    if (fromTargetTZ)
      return new Date(dateToShift.getTime() + (difference * 60 * 1000))
    else
      return new Date(dateToShift.getTime() - (difference * 60 * 1000))
  }

  browserSupportsTimeZoneId(tzId: string){
    try {
      new Date().toLocaleString("en-US", {timeZone: tzId})
      return true
    } catch (e) {
      return false
    }
  }

  get browserSupportedTimezones(): {label: string, value: string}[] {
    const timeZoneStrings: string[] = this.rawBrowserSupportedTimezones
    const result: {label: string, value: string}[] = []

    timeZoneStrings.forEach((name: string) => {
      result.push({
        label: name.replace("_", " "),
        value: name
      })
    })

    return result
  }

  get rawBrowserSupportedTimezones(): string[]{
    //@ts-ignore
    let rawAnswer: string[] = Intl.supportedValuesOf('timeZone') as string[]
    if (rawAnswer.indexOf("UTC") === -1){
      rawAnswer.push("UTC") //All browsers to support UTC as identifier but may not list it
    }
    return rawAnswer
  }

  set showTimezonesSelector(value: boolean) {
    if (!value) { //when its disabled, set timezone to standard
      this.timeZone = this.browserDefaultTimeZone
    }
    this.internal_showTimezonesSelector = value
  }

  get showTimezonesSelector(): boolean {
    return this.internal_showTimezonesSelector
  }

  get timezoneOfEventDiffersFromBrowser(): boolean {
    return this.timeZone !== this.browserDefaultTimeZone
  }

  findLocalizedTimeZoneName(tzId : string){
    if (tzId == "UTC") {
      return this.i18n.$gettext("Coordinated Universal Time")
    }
    try {
      return (new Date()).toLocaleDateString(undefined, {timeZone: tzId, timeZoneName: 'long'}).split(",")[1].trim()
    } catch (e) {
      return this.i18n.$gettext("Timezone name unknown")
    }
  }

  get formattedOriginalTime(): string {
    if (this.event?.start) {
      const tzId = this.safeGetTzId(this.event.timeZoneId)
      const start = this.shiftDateIntoTargetTimezone(new Date(this.event.start), tzId)
      let end: Date | null = null
      if (this.event.duration) {
        end = new Date(start.getTime() + DateTimeUtil.getMillisFromDuration(this.event.duration))
      } else if (this.event.end) {
        end = this.shiftDateIntoTargetTimezone(new Date(this.event.end), tzId)
      }
      const startString = this.event.allDay ? dayjs(start).format('dddd, L') : dayjs(start).format('dddd, LLL')
      const allDayEnd = end ? dayjs(end).add(-1, 'day').toDate(): null
      const timeZoneString = " (" + this.findLocalizedTimeZoneName(tzId) + ")"
      const sameDay: boolean = DateTimeUtil.isSameDay(start, this.event.allDay ? allDayEnd : end)
      if (!end || (sameDay && this.event.allDay)) {
        return startString  + timeZoneString
      } else if (end && sameDay) {
        return startString + ' - ' + dayjs(end).format('LT') + timeZoneString
      } else if (end && this.event.allDay) {
        return startString + ' - ' + dayjs(end).add(-1, 'day').format('dddd, L') + timeZoneString
      } else {
        return startString + ' - ' + dayjs(end).format('dddd, LLL') + timeZoneString
      }
    } else {
      return ''
    }

  }
}
</script>

<style scoped lang="scss">

</style>
