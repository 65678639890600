import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Event from '@/model/entry/Event';
import Contact from '@/model/entry/Contact';
import Page from '@/model/Page';
import { eventStore } from '@/store/EventStore';

export default class GeneratedSalesServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _createLead(event: Event, contact: Contact): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createLead', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Event(), data)
            eventStore.addOrReplaceEvent(model)
            return model.originalId
        })
    }

    _convertICSToEvents(fileToken: string): Promise<Event[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('convertICSToEvents', rpcParams, false).then((data: any) => {
            return data && Array.isArray(data) ? data.map(it => Object.assign(new Event(), it)) : Promise.reject()
        })
    }

    _getCampaigns(): Promise<any[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getCampaigns', rpcParams, false) as Promise<any[]>
    }

    _getSources(): Promise<any[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getSources', rpcParams, false) as Promise<any[]>
    }

    getEventsFilterByOriginalParentId(originalParentId: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number): Event[] {
        let events: Event[] = [...eventStore.state.events.values()]
        events = SortAndFilterUtil.filter(events, { originalParentId: originalParentId })
        SortAndFilterUtil.sort(events, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            events = events.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return events
    }

    getEventsFilterByQueryId(queryId: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number): Event[] {
        let events: Event[] = [...eventStore.state.events.values()]
        events = SortAndFilterUtil.filter(events, { queryId: queryId })
        SortAndFilterUtil.sort(events, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            events = events.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return events
    }

    getEvents(sortBy?: string[] | string, pageIndex?: number, pageSize?: number): Event[] {
        let events: Event[] = [...eventStore.state.events.values()]
        
        SortAndFilterUtil.sort(events, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            events = events.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return events
    }

    getEvent(originalId: string): Event | undefined {
        return eventStore.state.events.get(originalId)
    }
}
