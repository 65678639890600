import ContactsView from "@/components/contacts/ContactsView.vue"
import CalendarView from "@/components/calendar/CalendarView.vue"
import TaskView from "@/components/tasks/TaskView.vue"
import {createRouter, createWebHistory, NavigationFailure, RouteLocationNormalizedLoaded} from "vue-router"

//Named route constants:
const nameContacts = "Contacts"
const nameCalendar = "Calendar"
const nameTasks = "Tasks"

const routes = [
    {
        path: "/:calendar?/:event?",
        alias: "/calendar/:calendar?/:event?",
        name: nameCalendar,
        component: CalendarView
    },
    {
        path: "/contacts/:book?/:contact?",
        name: nameContacts,
        component: ContactsView
    },
    {
        path: "/tasks/:board?/:task?",
        name: nameTasks,
        component: TaskView
    }
]

//Router construction:

const router = createRouter({
    history: createWebHistory(),
    //@ts-ignore
    routes
})

//Quick jump methods:

export function toContacts(): void {
    void router.push({
        name: nameContacts
    })
}

export function toAddressBook(addressBookId: string): void {
    void router.push({
        name: nameContacts,
        params: {
            book: addressBookId
        }
    })
}

export function toContact(addressBookId: string, contactId: string): void {
    void router.push({
        name: nameContacts,
        params: {
            book: addressBookId,
            contact: contactId
        }
    })
}

export function toCalendars(): void {
    void router.push({
        name: nameCalendar
    })
}

export function toCalendar(calendarId: string): void {
    void router.push({
        name: nameCalendar,
        params: {
            calendar: calendarId
        }
    })
}

export function toEvent(calendarId: string, eventId: string): void {
    void router.push({
        name: nameCalendar,
        params: {
            calendar: calendarId,
            event: eventId
        }
    })
}

export function toTasks(): void {
    void router.push({
        name: nameTasks
    })
}

export function toTaskBoard(taskBoardId: string): void {
    void router.push({
        name: nameTasks,
        params: {
            board: taskBoardId
        }
    })
}

export function toTask(taskBoardId: string, taskId: string): void {
    void router.push({
        name: nameTasks,
        params: {
            board: taskBoardId,
            task: taskId
        }
    })
}

export default router
